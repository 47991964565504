@media screen and (min-width: 1080px) {
    .project {
        width: 48%;
        background-color: #F5F5F5;
        border-radius: 1em;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .projimg {
        width: 100%;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }

    .info {
        padding-left: 2em;
        margin-top: 1em;
        width: 90%;
    }

    .name {
        font-size: 1.5em;
        font-weight: bold;
    }

    .skills {
        font-size: 0.75em;
    }

    .details {
        margin-top: 1em;
        min-height: 4.5em;
        display: flex;
        align-items: center;
    }

    .buttons {
        width: 90%;
        display: flex;
        justify-content: space-between;
        margin: 1.5em auto;
        height: 2em;
        bottom: 0;

    }

    .projbutton {
        background-color: black;
        width: 48%;
        border-radius: 0.25em;
        color: white;
        font-size: 0.75em;
        font-weight: bold;
        cursor: pointer;
        transition-duration: 0.1s; 
        transition-timing-function: ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .projbutton:hover {
        box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3)
    }
}

@media screen and (max-width: 1079px) {
    .project {
        width: 100%;
        background-color: #F5F5F5;
        border-radius: 1em;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding-bottom: 0.1em;
        margin-bottom: 1.5em;
    }

    .projimg {
        width: 100%;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }

    .info {
        margin: 0 auto;
        margin-top: 1em;
        width: 90%;
    }

    .name {
        font-size: 1.5em;
        font-weight: bold;
    }

    .skills {
        font-size: 0.75em;
    }

    .details {
        margin-top: 1em;
        min-height: 4.5em;
        display: flex;
        align-items: center;
    }

    .buttons {
        width: 90%;
        display: flex;
        justify-content: space-between;
        margin: 1.5em auto;
        height: 2em;
    }

    .projbutton {
        background-color: black;
        width: 48%;
        border-radius: 0.25em;
        color: white;
        font-size: 0.75em;
        font-weight: bold;
        cursor: pointer;
        transition-duration: 0.1s; 
        transition-timing-function: ease;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .projbutton:hover {
        box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3)
    }
}