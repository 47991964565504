@media screen and (min-width: 1080px) {
    .container {
        width: 100%;
        background: #F5F5F5;
        height: 10.41em;
        margin-top: 22em;
        margin-bottom: 6em;
        padding-top: 0.5em;
        padding-bottom: 2em;
        overflow: hidden;
    }
    
    .title {
        font-size: 2em;
        font-weight: bold;
        margin: 0 auto;
        width: 50%;
        display: flex;
        justify-content: center;
    }
    
    .icons {
        height: 70%;
        width: 400%;
        margin-top: 1.5em;
        overflow: hidden;
    }
    
    
    .image {
        height: 3em;
        width: 3em;
        border-radius: 0;
        padding: 0;
        margin: 0;
    }
    
    .cimage {
        height: 3em;
        width: 2.669em;
        border-radius: 0;
        padding: 0;
        margin: 0;
    }
    
    .cssimage {
        height: 3em;
        width: 2.126em;
        border-radius: 0;
        padding: 0;
        margin: 0;
    }

    .sassimage {
        height: 3em;
        width: 4em;
        border-radius: 0;
        padding: 0;
        margin-left: -1em;
    }
    
    .figimage {
        height: 3em;
        width: 2.36em;
        border-radius: 0;
        padding: 0;
        margin: 0;
    }
    
    .rimage {
        height: 3em;
        width: 3.87em;
        border-radius: 0;
        padding: 0;
        margin: 0;
    }

    .nodeimage {
        height: 3em;
        width: 4.9em;
        border-radius: 0;
        padding: 0;
        margin: -1.2em;;
    }

    .tsimage {
        height: 3em;
        width: 3em;
        border-radius: 0;
        padding: 0;
    }
    
    .tickeritem {
        width: 100%;
        display: flex;
        align-items: center;
    }
    
    .icontickerwrap {
        overflow: hidden;
        width: 100%;
    }
    
    .iconmove {
        display: flex;
        overflow: hidden;
    }
    
    
    @keyframes tickerh {
        0% { 
            transform: translateX(0%); 
        }
    
        100% { 
            transform: translateX(-75%); 
        }
    }
    
    
    .iconmove { animation: tickerh linear 500s infinite; }    
}

@media screen and (max-width: 1079px) {
    .container {
        width: 100%;
        background: #F5F5F5;
        height: 8em;
        margin-top: 42em;
        margin-bottom: 2.5em;
        padding-top: 0.5em;
        padding-bottom: 2em;
        overflow: hidden;
    }

    .title {
        font-size: 1.5em;
        font-weight: bold;
        margin: 0 auto;
        width: 50%;
        display: flex;
        justify-content: center;
    }

    .icons {
        height: 70%;
        width: 400%;
        margin-top: 0.25em;
        overflow: hidden;
    }

    .image {
        height: 3em;
        width: 3em;
        border-radius: 0;
        padding: 0;
        margin: 0 1.6em;
    }

    .cimage {
        height: 3em;
        width: 2.669em;
        border-radius: 0;
        padding: 0;
        margin: 0 1.8em;
    }
    
    .cssimage {
        height: 3em;
        width: 2.126em;
        border-radius: 0;
        padding: 0;
        margin: 0 1.6em;
    }

    .sassimage {
        height: 3em;
        width: 4em;
        border-radius: 0;
        padding: 0;
        margin: 0 1em;
    }
    
    .figimage {
        height: 3em;
        width: 2.36em;
        border-radius: 0;
        padding: 0;
        margin: 0 1.6em;
    }
    
    .rimage {
        height: 3em;
        width: 3.87em;
        border-radius: 0;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 1.2em;
        margin-right: 3em;
    }

    .nodeimage {
        height: 3em;
        width: 4.9em;
        border-radius: 0;
        padding: 0;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 1em;
        margin-right: 3em;
    }

    .tsimage {
        height: 3em;
        width: 4.9em;
        border-radius: 0;
        padding: 0;
        margin-top: 0em;
        margin-bottom: 0em;
        margin-left: 1.8em;
        margin-right: 3em;
    }

    .tickeritem {
        width: 100%;
        display: flex;
        align-items: center;
        margin-left: 1em;
    }
    
    .icontickerwrap {
        overflow: hidden;
        width: 100%;
    }
    
    .iconmove {
        display: flex;
        overflow: hidden;
    }
    
    
    @keyframes tickerh {
        0% { 
            transform: translateX(0%); 
        }
    
        100% { 
            transform: translateX(-75%); 
        }
    }
    
    
    .iconmove { animation: tickerh linear 500s infinite; }
}