.projbutton {
    background-color: black;
    width: 100%;
    border-radius: 0.25em;
    color: white;
    font-size: 0.75em;
    font-weight: bold;
    cursor: pointer;
    transition-duration: 0.1s; 
    transition-timing-function: ease; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.projbutton:hover {
    box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3)
}